<template>
  <div v-if="getLoaded()" class="duck-form form-width-large form-center">
    <NotiBar v-if="msg.show" :type="msg.type" :msg="msg.text" />
    
    
    <form @submit.prevent="saveForm">

      <fieldset class="form-block">

        <div class="form-container">
          <div class="input">
            <Label text="หัวข้อของป้าย" />
            <input type="text" v-model="contentData.title">
          </div>
          
          <div class="input row2">
            <div class="col">
              <Label text="สถานะการแสดงป้าย" />
              <select v-model="contentData.status">
                <option value="draft">ปิด, ไม่ให้แสดง</option>
                <option value="publish">เปิดให้แสดง</option>
              </select>
            </div>
            <div class="col">
              <Label text="เปิดให้ลิ้งค์ไปหน้ารายละเอียด?" />
              <select v-model="contentData.meta.link_status">
                <option value="close">ปิด, ไม่ให้เปิดดูได้</option>
                <option value="open">เปิดให้ดูได้</option>
              </select>
            </div>
          </div>

          <div class="input">
            <Label text="รูปภาพประกอบ" />
            <SingleImageUploader type="thumbnail" section="content" :parentId="contentData.ID" description="ใช้รูปขนาด 1,400x1,400 pixel" />
          </div>

          <div v-if="contentData.meta.link_status=='open'" class="input">
            <Label text="รายละเอียด" />
            <TextEditor v-model="contentData.detail" />
          </div>
        </div>
      </fieldset>

      <div class="button-block">
        <button type="submit" class="btn btn-update">
          <i class="fa fa-save fa-lg"></i> บันทึกข้อมูล
        </button>
      </div>
    </form>
  </div>
</template>


<script>
// @ is an alias to /src
import {apiRequest}        from '@/utils/axios/axiosInstance.js';

import Label               from '@/components/common/form/Label.vue';
import NotiBar             from '@/components/common/NotiBar.vue';
import SingleImageUploader from '@/components/file/SingleImageUploader.vue';
import TextEditor          from '@/components/common/form/TextEditor.vue';

export default {
  data() {
    return {
      loaded: false,
      msg: {
        show: false,
        type: 'success',
        text: '',
      },

      contentSlug: '',
      contentData: {},
    }
  },
  components: {
    Label,
    NotiBar,
    SingleImageUploader,
    TextEditor
  },
  created() {
    this.contentSlug = 'promo-popup';

    this.$store.commit('setPageTitle', 'Promotion Pop Up');
    this.$store.commit('setHeadTitle', 'Promotion Pop Up');

    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage:function() {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .get('/content/page/'+this.contentSlug)
        .then( (res) => {
          if(res.data.status==200) {
            if(res.data.row==0) {
              this.saveDraft();
            }else{
              this.contentData = res.data.result;

              // if this is new page
              if(this.contentData.status=='new') {
                this.contentData.status = 'draft';
                this.contentData.meta = {
                  link_status: 'close'
                };
              }

              this.$store.commit('setLoaderShow', false); // hide page loading
            }
          }
        });
    },
    saveDraft:function() {
      console.log('save draft');
      apiRequest
        .post('/content/add-page-draft', { slug: this.contentSlug })
        .then( () => this.loadPage() );
    },
    saveForm:function() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .post('/content/save-post', this.contentData)
        .then( (res) => {
          this.msg.show = true;
          if(res.data.status==200) {
            this.msg.text = 'Save data complete';
          }else{
            this.msg.type = 'error';
            this.msg.text = 'Save data fail. Please, try again.';
          }
          this.$store.commit('setLoaderShow', false);
        });
    },
  }
}
</script>

<style scoped>
.col-thumb {padding-right: 15px;}

.preview-link {
  margin-top: 5px;
}
.select-time {
  padding-left: 5px;
  width: 45px;
}
</style>